import React, { useState } from 'react';
import { assets } from "../utils/helper/media";

function LanguageDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState();

    const languages = ['English'];

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left">
            <button
                onClick={toggleDropdown}
                className="inline-flex justify-center gap-2 items-center text-white md:text-[14px] text-[10px]"
                // className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
                {selectedLanguage??'Language'}
                
                <img src={assets.down} alt='rutain pharma'/>
            </button>

            {isOpen && (
                <div className="z-[10] origin-top-right absolute right-0 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {languages.map((language) => (
                            <button
                                key={language}
                                onClick={() => handleLanguageChange(language)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                                {language}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default LanguageDropdown;
