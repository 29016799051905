import { useNavigate } from "react-router-dom"
import { Button } from "../Component/Button"
import { useEffect } from "react"
import { assets } from "../utils/helper/media";

const Blogs = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="w-full relative h-[50vh] sm:h-[60vh] lg:h-[70vh] bg-[#072C2C]">
                <img src={assets.blog_bg_1} alt="rutain pharma" className="absolute top-0 left-0  w-[30%]" />
                <img src={assets.blog_bg_2} alt="rutain pharma" className="absolute bottom-0 right-0 hidden w-[20%] lg:block" />
                <div className="2xl:text-[94px] md:left-[35%]  w-[50%] md:w-[35%]  text-[32px] md:text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#25BEB2] to-[#A1EEE1] absolute top-1/2 left-1/2 transform -translate-x-[0%] -translate-y-[50%] text-center">
                    Our Blogs
                </div>
            </div>
            <div className="my-10 container">
                <div className="relative grid md:grid-cols-2 grid-cols-1 gap-x-10 mt-10">
                    <div className="group  overflow-hidden mb-10 h-fit relative" style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}>
                        <img src={assets.blog} alt="rutain-pharma" className="w-full h-auto transition-transform duration-300 group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 px-3 md:px-10 py-3"> {/* Add z-index for text visibility */}
                            <p className="font-inter text-[20px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                                Celebrating the Backbone of Healthcare: World Pharmacist Day
                            </p>
                            <div className="flex gap-5">
                                <p className="flex gap-1 hover:border-b border-[#FECCE3]  underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer" onClick={() => navigate('/blog-details')}>
                                    Read More <img src={assets.up_arrow} alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white">5 minutes read</p>
                            </div>
                        </div>
                    </div>
                    <div className="group  overflow-hidden mb-10 relative" style={{ boxShadow: '10px 10px 0px rgba(105, 223, 208, 0.6)' }}>
                        <img src={assets.blog_3} alt="rutain-pharma" className="w-full h-auto transition-transform duration-300 group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 px-3 md:px-10 py-3"> {/* Add z-index for text visibility */}
                            <p className="font-inter text-[20px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF]">
                                Safe and Effective Medication: Tips and Best Practices
                            </p>
                            <div className="flex gap-5">
                                <p className="flex gap-1 hover:border-b border-[#A1EEE1] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF]] cursor-pointer" onClick={() => navigate('/blog-details1')}>
                                    Read More <img src={assets.up_arrow} alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white">5 minutes read</p>
                            </div>
                        </div>
                    </div>
                    <div className="group  md:bottom-[25%] overflow-hidden mb-10 h-fit relative" style={{ boxShadow: '10px 10px 0px rgba(105, 223, 208, 0.6)' }}>
                        <img src={assets.blog_4} alt="rutain-pharma" className="w-full h-auto transition-transform duration-300 group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 px-3 md:px-10 py-3"> {/* Add z-index for text visibility */}
                            <p className="font-inter text-[20px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF]">
                                Overview of the Pharma Industry
                            </p>
                            <div className="flex gap-5">
                                <p className="flex gap-1 hover:border-b border-[#A1EEE1] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF] cursor-pointer" onClick={() => navigate('/blog-details2')}>
                                    Read More <img src={assets.up_arrow} alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white">5 minutes read</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Blogs