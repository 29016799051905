import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../Component/Button';
import ProductList from '../Component/ProductList';
import { productData } from '../jsondata';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { assets } from "../utils/helper/media";

const ProductDetails = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    message: "",
    casNo:"",
    company:"rutain"
  });
  const [submitted, setSubmitted] = useState(false);

  const contactRef = useRef(null); // Ref to the "Let’s Get In Touch" section

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({
        behavior: "smooth", // smooth scrolling
        block: "start", // align at the start of the section
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, mobileNumber, message } = formData;
    if (!firstName || !lastName || !email || !mobileNumber || !message) {
      alert("Please fill out all fields before submitting.");
      return;
    }
    setSubmitted(true);

    const payload = { ...formData, casNo: product.casNo }; // Add casNo to the payload

    try {
      const res = await fetch('https://39w5n8hf91.execute-api.ap-south-1.amazonaws.com/contact-us/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (res.ok) {
        toast.success("Form submitted successfully.")
      } else {
        toast.error("Failed to submit form.")
      }
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        message: "",
        company: "rutain",
    })
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitted(false);
    }
  };

  const location = useLocation();
  const { product } = location.state || {}; // Get the product data from state

  useEffect(() => {
    if (product?.casNo) {
      setFormData((prev) => ({ ...prev, casNo: product.casNo }));
    }
  }, [product]);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate()
  const handleSendInquiry = (data) => {
    console.log(data, 'a')
    navigate(`/product-details/${product?.category?.replaceAll(' ', '-')}/${data?.name?.replaceAll(' ', '-')}`, { state: { product: data }, replace: true },);
  };

  if (!product) {
    return <p>No product details available.</p>;
  }

  const relatedProducts = productData.filter(
    (p) => p.category === product.category && p.name !== product.name
  );
  const finalProduct = { ...relatedProducts[0], products: relatedProducts[0]?.products?.filter(item => item.name !== product.name) }
  const length = finalProduct?.products?.length
  return (
    <div className="container mt-10">
      <ToastContainer/>
      <div className='flex flex-col md:flex-row gap-5 '>
        <div className='w-[100%] md:w-[50%]'>
          <img src={product.image} alt='rutain pharma' className='w-[60%] mx-auto py-5' />
        </div>
        <div className='w-[100%] md:w-[50%]'>
          {/* <p><span onClick={() => navigate('/')} className='cursor-pointer'>Home</span> / <span onClick={() => navigate('/product')} className='cursor-pointer'>Product</span> / {product.name}</p> */}
          <div className='w-[100%] md:w-[70%]'>
            <div className="mx-auto w-fit  my-3 relative z-[20]">
              <h1 className="relative text-[22px] md:text-[28px] font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
                {product.name}
                <img
                  src="/images/img_three_hexagons.svg"
                  alt="rutain-pharma"
                  className="absolute w-6 h-6 top-[-10px] right-[-20px]"
                />
              </h1>
            </div>
          </div>
          {/* <div className='flex items-center gap-5 border-b-2 pb-3'>
            <p className='text-red-500'>{product.price} INR/Kilograms</p>
            <button className='text-[#184948] border-[#A1EEE1] border rounded-lg text-[12px] px-3 py-1.5'>Send Inquiry</button>
            <Button
              shape='round'
              className='min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black border-[#A1EEE1] border-2'
            style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
            >
              Send Inquiry
            </Button>
          </div> */}
          <div className='my-5'>
            <p className='font-semibold my-3'>Product Details: </p>
            <div className="w-[100%] md:w-[70%]">
              {product.casNo && <p className="text-gray-600 border-b py-2">CAS No: {product.casNo}</p>}
              {product.storage && <p className="text-gray-600 border-b py-2">Storage: {product.storage}</p>}
              {product.color && <p className="text-gray-600 border-b py-2">Color: {product.color}</p>}
              {product.form && <p className="text-gray-600 border-b py-2">Form: {product.form}</p>}
              {product.smell && <p className="text-gray-600 border-b py-2">Smell: {product.smell}</p>}
              {product.placeOfOrigin && <p className="text-gray-600 border-b py-2">Place of Origin: {product.placeOfOrigin}</p>}
              {product.grade && <p className="text-gray-600 border-b py-2">Grade: {product.grade}</p>}
              {product.purity && <p className="text-gray-600 border-b py-2">Purity: {product.purity}</p>}
              {product.supplyAbility && <p className="text-gray-600 border-b py-2">Supply Ability: {product.supplyAbility}</p>}
              {product.application && <p className="text-gray-600 border-b py-2">Application: {product.application}</p>}
              {product.otherNames && <p className="text-gray-600 border-b py-2">Other Names: {product.otherNames}</p>}
              {product.molecularFormula && <p className="text-gray-600 border-b py-2">Molecular Formula: {product.molecularFormula}</p>}
              {/* <p className="border-b py-2 text-[#188983] underline">Click to View More</p> */}
              <div className='flex gap-3 items-center border-b py-2'>
                <p className="text-gray-600 ">Share Your Product</p>
                <img src={assets.facebook} alt=' ' />
                <img src={assets.twiter} alt=' ' />
                <img src={assets.linkdin} alt=' ' />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className='w-[100%] md:w-[80%] mx-auto my-10'>
        <p className='text-[18px] md:text-[22px] font-bold font-inter border-b-2 pb-2 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]'>{product.name}</p>
        <div className='w-[100%] flex justify-between items-center text-[14px] md:text-[16px]'>
          {product.price &&
          <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row justify-between items-center'>
            <p>Price</p>
            <p className='text-gray-600 '>{product.price} INR/Kilogram</p>
          </div>}
          <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row justify-between items-center'>
            <p>Minimum Order Quantity</p>
            <p className='text-gray-600 '>50 Kilogrames</p>
          </div>
        </div>
      </div>
      <div className='w-[100%] md:w-[80%] mx-auto my-10'>
        <p className='text-[18px] md:text-[22px] font-bold font-inter border-b-2 pb-2 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]'>{product.name} Product Specifications</p>
        <div className='w-[100%] flex justify-between items-center text-[14px] md:text-[16px]'>
          <div className='w-[40%] '>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Molecular Formula</p>
              <p className='text-gray-600 '>C8H17NO2</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Molecular Formula</p>
              <p className='text-gray-600 '>C8H17NO2</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>CAS No</p>
              <p className='text-gray-600 '> 148553-50-8</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Storage</p>
              <p className='text-gray-600 '>Other</p>
            </div>
          </div>
          <div className='w-[40%]'>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Type</p>
              <p className='text-gray-600 '>Other</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Place of Origin</p>
              <p className='text-gray-600 '>Ankleshwar</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>HS Code</p>
              <p className='text-gray-600 '>2942</p>
            </div>
            <div className=' flex flex-col md:flex-row justify-between items-center py-1'>
              <p>Color</p>
              <p className='text-gray-600 '>Other</p>
            </div>
          </div>
        </div>
      </div>

      <div className='w-[100%] md:w-[80%] mx-auto'>
        <p className='text-[18px] md:text-[22px] font-bold font-inter border-b-2 pb-2 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]'>{product.name} Trade Information</p>
        <div className='w-[100%] flex justify-between items-center text-[14px] md:text-[16px]'>
          <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row justify-between items-center'>
            <p>Delivery Time</p>
            <p className='text-gray-600 '>Days</p>
          </div>
          <div className='w-[100%] md:w-[40%] flex flex-col md:flex-row justify-between items-center'>
            <p>Sample Available</p>
            <p className='text-gray-600 '>Yes</p>
          </div>
        </div>
      </div>


      <div className='w-[100%] md:w-[80%] mx-auto my-10'>
        <p className='text-[18px] md:text-[22px] font-bold font-inter border-b-2 pb-2 mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]'>Product Description</p>
        <div className='w-[100%] text-center text-gray-600 text-[14px] md:text-[16px]'>
          {product.description && <p>{product.description}</p>}
        </div>
      </div>


      <div className="mx-auto w-fit  my-3 mt-20 relative ">
        <h1 className="relative text-[20px] md:text-[38px] font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
          Provide Order Specifications
          <img
            src={assets.img_three_hexagons_svg}
            alt="rutain-pharma"
            className="absolute w-4 top-[0px] right-[-15px]"
          />
        </h1>
      </div>
      <div className='w-[100%] md:w-[70%] mx-auto '>

        <div className="mx-auto w-fit  my-3 mt-20 relative ">
        </div>
        <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-center md:justify-between my-5 px-5 md:px-0">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%]  border-[#175856]"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%]  border-[#175856]"
          />
        </div>
        <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-center md:justify-between my-5 px-5 md:px-0">
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%]  border-[#175856]"
          />
          <input
            type="number"
            name="mobileNumber"
            placeholder="Mobile Number"
            value={formData.mobileNumber}
            onChange={handleInputChange}
            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%]  border-[#175856]"
          />
        </div>

        <textarea
          rows={5}
          name="message"
          placeholder="More about your requirements..."
          value={formData.message}
          onChange={handleInputChange}
          className="px-[70px] py-3 mt-2 rounded-full border-2 w-[100%]  bg-transparent border-[#175856]"
        />
        <div className="flex justify-center">

          <div className="flex justify-center">
            <Button
              onClick={handleSubmit}
              shape="round"
              className="my-5 min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out'
                            // style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}"
              disabled={submitted}
            >
              {submitted ? "Submitting..." : "Send Inquiry"}
            </Button>
          </div>
        </div>
      </div>

      <div className='w-[100%] md:w-[80%] mx-auto my-10 mt-20' >

        {finalProduct?.products?.length > 0 && <div className="mx-auto w-fit  my-3 mt-20 relative z-[20]">
          <h1 className="relative text-[20px] font-semibold font-inter text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
            Other Products
            {/* <img
              src="/images/img_three_hexagons.svg"
              alt="rutain-pharma"
              className="absolute w-3 top-[0px] right-[-15px]"
            /> */}
          </h1>
        </div>}
        {finalProduct?.products?.length > 0 &&
          <>
            <p className='text-[34px] font-inter font-semibold mx-auto w-fit text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA] my-5'>{finalProduct.category}</p>
            <div className={`grid grid-cols-2 md:grid-cols-${length} gap-8`}>
              {finalProduct.products && finalProduct.products.length > 0 ? (
                finalProduct.products.map((relatedProduct, index) => (
                  <div key={index} className="m-auto ">
                    <img src={relatedProduct.image} alt='rutain pharma' className='' />
                    <div className="w-[100%] text-[15px] min-h-[65%] md:min-h-[45%]" >
                      <p className='text-[16px] px-3 text-center'>
                        {relatedProduct.name}
                      </p>
                      {relatedProduct.casNo && <p className="text-gray-600 border-b py-2">CAS No: {relatedProduct.casNo}</p>}
                      {relatedProduct.storage && <p className="text-gray-600 border-b py-2">Storage: {relatedProduct.storage}</p>}
                      {relatedProduct.color && <p className="text-gray-600 border-b py-2">Color: {relatedProduct.color}</p>}
                      {relatedProduct.form && <p className="text-gray-600 border-b py-2">Form: {relatedProduct.form}</p>}
                      {relatedProduct.smell && <p className="text-gray-600 border-b py-2">Smell: {relatedProduct.smell}</p>}
                      {relatedProduct.placeOfOrigin && <p className="text-gray-600 border-b py-2">Place of Origin: {relatedProduct.placeOfOrigin}</p>}
                      {relatedProduct.grade && <p className="text-gray-600 border-b py-2">Grade: {relatedProduct.grade}</p>}
                      {relatedProduct.purity && <p className="text-gray-600 border-b py-2">Purity: {relatedProduct.purity}</p>}
                      {relatedProduct.supplyAbility && <p className="text-gray-600 border-b py-2">Supply Ability: {relatedProduct.supplyAbility}</p>}
                      {relatedProduct.application && <p className="text-gray-600 border-b py-2">Application: {relatedProduct.application}</p>}
                      {relatedProduct.otherNames && <p className="text-gray-600 border-b py-2">Other Names: {relatedProduct.otherNames}</p>}
                      {relatedProduct.molecularFormula && <p className="text-gray-600 border-b py-2">Molecular Formula: {relatedProduct.molecularFormula}</p>}
                    </div>
                    <Button
                      shape="round"
                      className={`min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-black ${index % 2 === 0 ? 'bg-[#A1EEE1] hover:shadow-[0_8px_35px_rgba(105,223,208,0.8)]' : 'bg-[#FECCE3] hover:shadow-[0_8px_35px_rgba(233,31,100,0.6)]'
                        } shadow-[0_6px_30px rgba(105,223,208,0)] transition-all duration-300 ease-in-out`}
                      // style={{
                      //   boxShadow:
                      //     index % 2 === 0
                      //       ? '0 6px 30px rgba(105, 223, 208, 0.1)'
                      //       : '0 6px 30px rgba(233, 31, 100, 0.1)',
                      // }}
                      onClick={() => handleSendInquiry(relatedProduct)}
                    >
                      More Info
                    </Button>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">No related products found.</p>
              )}
            </div>
          </>}
      </div>
      {/* Add more fields as needed */}
    </div>
  );
};

export default ProductDetails;
