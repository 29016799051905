// CategoryPage.js
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../Component/Button';
import ProductCard from '../Component/ProductCard';
import { productData } from '../jsondata';
import { assets } from "../utils/helper/media";

const CategoryProducts = () => {
  const { categoryName } = useParams();

  const formattedCategoryName = categoryName.replaceAll(' ', '-');
  let videoSrc;
  if (formattedCategoryName === 'Bulk-Drugs-API') {
    videoSrc = assets.drugs; // Path to vid1
  } else {
    videoSrc = assets.intermediate; // Fallback video
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // Find the category based on the URL parameter
  const categoryData = productData.find(category => category.category.replaceAll(' ', '-').toLowerCase() === categoryName.toLowerCase());

  const navigate = useNavigate()
  if (!categoryData) {
    return <p className="text-center text-red-500">Category not found.</p>;
  }


  return (
    <div>
      <div className="relative h-[100vh] bg-[#072C2C]">
        <img src={assets.bg_leaf} alt="rutain-pharma" className="absolute top-0 left-0 z-[5] hidden md:block" />
        <img src={assets.bg_leaf_2} alt="rutain-pharma" className="absolute bottom-0 right-0 z-[5] hidden md:block" />


        <div className="flex w-[80%] mx-auto items-center h-full z-[10] justify-between bg-[#072C2C]">
          <div className="w-[100%] md:w-[50%] relative z-[20]">
            <h1 className="text-[30px] lg:text-[48px] my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
              {categoryName}
            </h1>
            <p className="text-[14px] md:text-[16px] font-poppins leading-[1.7] text-[#F1FCF9] bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
              Pharmaceutical Chemicals we offer are demanded in the industries of pharmaceutical. They are helpful for the diagnosis, prevention, and treatment of diseases. They have the attributes for restoring, modifying, and correcting several organic functions. They are used in pharmaceutical industry for the formation of several types of medicines. Pharmaceutical chemicals are widely used by humans for rapid food production. They are special types of chemicals, which help in formulation of drugs. Offered compounds are accessible with disinfectant efficiency as well as good residual performance. They are used to make drugs for pain, allergy, and other conditions.
            </p>
            {/* <Button
              shape="round"
              className="min-w-[118px]  mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#072C2C] bg-[#A1EEE1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
              //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
              onClick={() => navigate(`/product/${categoryName.replaceAll(' ','-')}`)}
            >
              Know More
            </Button> */}
          </div>
          <div
            className="rounded-[10%] overflow-hidden cursor-pointer h-[500px] relative lg:w-[30%] z-[6]"
            style={{ boxShadow: '7px 7px 0px rgba(233, 31, 100, 1)' }}
          // onClick={() => navigate('/API-Intermediates')} 
          >
            <video
              className="absolute  top-0 left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
              controls={false}
              loop
              autoPlay
              muted
            >
              <source src={videoSrc} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
      <div className='flex justify-between container w-full items-center gap-5'>
        <div className='w-[40%]'>

          <input
            type="text"
            placeholder="Search"
            // value={searchTerm}
            // onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[100%] p-2 px-5  border-2 border-[#188983] rounded-full focus:outline-none  outline-none"
          />
          {/* <img src='/images/img_rewind.svg' alt='rutain pharma' className='text-[#188983]'/> */}
        </div>
        <div className='flex gap-5 w-[40%] '>
        {/* <Button
          shape="round"
          className={`min-w-[118px]  my-5 rounded-[12px] px-5 font-poppins font-medium text-black   bg-[#A1EEE1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)]
                      }  transition-all duration-300 ease-in-out`}
        // style={{
        //   boxShadow:
        //     index % 2 === 0
        //       ? '0 6px 30px rgba(105, 223, 208, 0.1)'
        //       : '0 6px 30px rgba(233, 31, 100, 0.1)',
        // }}
        >
        See All
        </Button> */}
        </div>
        <Button
          shape="round"
          className={`min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black   bg-[#FECCE3] hover:shadow-[0_4px_163px_rgba(233,31,100,1)]
                      }  transition-all duration-300 ease-in-out`}
        // style={{
        //   boxShadow:
        //     index % 2 === 0
        //       ? '0 6px 30px rgba(105, 223, 208, 0.1)'
        //       : '0 6px 30px rgba(233, 31, 100, 0.1)',
        // }}
        >
          Clear All
        </Button>
      </div>
      <div className="container">
        <h1 className="text-center text-[36px] my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
          {categoryData.category}
        </h1>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-y-5 md:gap-y-0">

          {categoryData.products.length > 0 ? (
            categoryData.products.map((product, index) => (
              <ProductCard key={index} product={product} index={index} category={categoryData.category} />
            ))
          ) : (
            <p className="text-gray-500">No products found in this category.</p>
          )}
        </div>
        <Button
          shape="round"
          className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 hover:shadow-[0_8px_35px_rgba(105,223,208,0.8)] transition-all duration-300 ease-in-out font-poppins font-medium text-black bg-[#A1EEE1] shadow-[0_6px_30px_rgba(105,223,208,0.6)]"
          // style={{
          //   boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)',
          // }}
          onClick={() => window.history.back()}
        >
          Back to Products
        </Button>
      </div>
    </div>
  );
};

export default CategoryProducts;
