export const api = "/images/api.mp4";
export const bg_leaf_2 = "/images/bg-leaf-2.png";
export const bg_leaf_3 = "/images/bg-leaf-3.png";
export const bg_leaf = "/images/bg-leaf.png";
export const blog_1 = "/images/blog-1.png";
export const blog_3 = "/images/blog-3.png";
export const blog_4 = "/images/blog-4.png";
export const blog_5 = "/images/blog-5.png";
export const blog_banner = "/images/blog-banner.png";
export const blog_bg_1 = "/images/blog-bg-1.png";
export const blog_bg_2 = "/images/blog-bg-2.png";
export const blog = "/images/blog.png";
export const building = "/images/building.svg";
export const call_1 = "/images/call-1.svg";
export const call = "/images/call.svg";
export const ceoImage = "/images/ceo-image.jpg";
export const certi_2 = "/images/certi2.jpg";
export const choose_1 = "/images/choose-1.png";
export const choose_2 = "/images/choose-2.png";
export const choose_3 = "/images/choose-3.png";
export const cloud = "/images/cloud.svg";
export const company_banner = "/images/company-banner.jpg";
export const company_banner_png = "/images/company-banner.png";
export const company_img = "/images/company-img.jpg";
export const company_profile = "/images/company-profile.jpg";
export const contact_banner = "/images/contact-banner.png";
export const contact = "/images/contact.png";
export const cto_1 = "/images/cto-1.png";
export const cto_2 = "/images/cto-2.png";
export const cto_3 = "/images/cto-3.png";
export const down = "/images/down.svg";
export const drugs = "/images/drugs.webm";
export const duns = "/images/duns.jpg";
export const dustbin = "/images/dustbin.svg";
export const facebook = "/images/facebook.svg";
export const file = "/images/file.svg";
export const final_logo_1 = "/images/final-logo-1.png";
export const final_logo_2 = "/images/final-logo-2.png";
export const final_logo = "/images/final-logo.png";
export const footer_1 = "/images/footer-1.png";
export const footer_2 = "/images/footer-2.png";
export const footer_3 = "/images/footer-3.png";
export const footer_4 = "/images/footer-4.png";
export const footer_5 = "/images/footer-5.png";
export const footer_bg = "/images/footer-bg.png";
export const fuel = "/images/fuel.svg";
export const home_page = "/images/home-page.webm";
export const img_call = "/images/img-call.svg";
export const img_rewind = "/images/img-rewind.svg";
export const img_rutain_watermark = "/images/img-rutain-watermark.png";
export const img_three_hexagons = "/images/img-three-hexagons.png";
export const img_three_hexagons_svg = "/images/img-three-hexagons.svg";
export const intermediate = "/images/intermediate.webm";
export const leaf = "/images/leaf.svg";
export const linkdin = "/images/linkdin.svg";
export const location_1 = "/images/location-1.svg";
export const location = "/images/location.svg";
export const mail = "/images/mail.svg";
export const person = "/images/person.svg";
export const product_1 = "/images/product-1.png";
export const product_2 = "/images/product-2.png";
export const product_3 = "/images/product-3.png";
export const product_banner = "/images/product-banner.png";
export const product = "/images/product.png";
export const recycle = "/images/recycle.svg";
export const robots = "/images/robots.txt";
export const save_water = "/images/save-water.svg";
export const send = "/images/send.svg";
export const slide_1 = "/images/slide-1.jpg";
export const slide_2 = "/images/slide-2.jpg";
export const slide_3 = "/images/slide-3.jpg";
export const slide_4 = "/images/slide-4.jpg";
export const slide_5 = "/images/slide-5.jpg";
export const slide_6 = "/images/slide-6.jpg";
export const sms = "/images/sms.svg";
export const time = "/images/time.svg";
export const trusted_seller = "/images/trusted-seller.png";
export const twiter = "/images/twiter.svg";
export const up_arrow = "/images/up-arrow.svg";
export const vector_1 = "/images/vector-1.png";
export const vector_2 = "/images/vector-2.png";
export const vector_3 = "/images/vector-3.png";
export const vector_4 = "/images/vector-4.png";
export const vector_5 = "/images/vector-5.png";
export const video_1 = "/images/video-1.webm";
export const video_2 = "/images/video-2.webm";
export const video_3 = "/images/video-3.webm";
export const watermark = "/images/watermark.png";
export const rabeprazole = "/images/rabeprazole.png";
export const minoxidil = "/images/minoxidil.png";
export const pregabalin = "/images/pregabalin.png";
export const fenofibrate = "/images/fenofibrate.png";
export const pantaprazole = "/images/pantaprazole.png";
export const dextromethorphan = "/images/dextromethorphan-hbr.png";
export const artemether = "/images/artemether.png";
export const aminoAcetophenone2 = "/images/amino-acetophenone-2.png";
export const chloromethyl4methylquinazoline1 = "/images/chloromethyl-4-methylquinazoline-1.jpg";
export const aminoPiperidineDihydrochloride = "/images/amino-piperidine-dihydrochloride.jpg";
export const bromo72Butynl37Dihydro3Methyl1hPurine26dione = "/images/bromo-7-2-butynl-3-7-dihydro-3-methyl-1h-purine-2-6-dione.jpg";

export const assets = {
  api,
  bg_leaf_2,
  bg_leaf_3,
  bg_leaf,
  blog_1,
  blog_3,
  blog_4,
  blog_5,
  blog_banner,
  blog_bg_1,
  blog_bg_2,
  blog,
  building,
  call_1,
  call,
  ceoImage,
  certi_2,
  choose_1,
  choose_2,
  choose_3,
  cloud,
  company_banner_png,
  company_banner,
  company_img,
  company_profile,
  contact_banner,
  contact,
  cto_1,
  cto_2,
  cto_3,
  down,
  drugs,
  duns,
  dustbin,
  facebook,
  file,
  final_logo_1,
  final_logo_2,
  final_logo,
  footer_1,
  footer_2,
  footer_3,
  footer_4,
  footer_5,
  footer_bg,
  fuel,
  home_page,
  img_call,
  img_rewind,
  img_rutain_watermark,
  img_three_hexagons_svg,
  img_three_hexagons,
  intermediate,
  leaf,
  linkdin,
  location_1,
  location,
  mail,
  person,
  product_1,
  product_2,
  product_3,
  product_banner,
  product,
  recycle,
  robots,
  save_water,
  send,
  slide_1,
  slide_2,
  slide_3,
  slide_4,
  slide_5,
  slide_6,
  sms,
  time,
  trusted_seller,
  twiter,
  up_arrow,
  vector_1,
  vector_2,
  vector_3,
  vector_4,
  vector_5,
  video_1,
  video_2,
  video_3,
  watermark,
  rabeprazole,
  minoxidil,
  pregabalin,
  fenofibrate,
  pantaprazole,
  dextromethorphan,
  artemether,
  aminoAcetophenone2,
  chloromethyl4methylquinazoline1,
  aminoPiperidineDihydrochloride,
  bromo72Butynl37Dihydro3Methyl1hPurine26dione,
};
