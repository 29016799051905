import {assets} from "./utils/helper/media"

// export const productData = [
//     {
//       category: 'API Intermediates',
//       products: [
//         {
//           name: '2-Amino Acetophenone',
//           casNo: '551-93-9',
//           image: '',
//           type: 'Other',
//           smell: 'Other',
//           color: 'Other',
//           casNo: '148553-50-8',
//           storage: 'Ankleshwar',
//           placeOfOrigin: 'Other',
//           hsCode: '2942',
//           price: '7500',
//         },
//         {
//           name: '2-(Chloromethyl)-4-methylquinazoline',
//           image: '',
//           casNo: '109113-72-6',
//           storage: 'Room Temperature',
//           placeOfOrigin: 'India',
//           grade: 'Medicine Grade',
//           form: 'Powder',
//           smell: 'Other',
//           purity: 'High',
//           price: '7500',
//         },
//         {
//           name: '(R)-3-Amino-piperidine dihydrochloride',
//           image: '',
//           casNo: '334618-23-4',
//           placeOfOrigin: 'Ankleshwar',
//           molecularFormula: 'C13H21NO3',
//           price: '7500',
//         },
//         {
//           name: '8-Bromo-7-(2-butynl)-3,7-dihydro-3-methyl-1H-purine-2,6-dione',
//           casNo: '666816-98-4',
//           image: '',
//           grade: 'Medicine Grade',
//           otherNames: '1-Naphthalene Methylamine Hydrochloride Ambroxol HCL81',
//           storage: 'Room Temperature',
//           placeOfOrigin: 'India',
//           purity: 'High',
//           supplyAbility: '5000 per Month',
//         },
//       ],
//     },
//     {
//       category: 'Bulk Drugs API',
      
//       products: [
//         {
//           name: 'Minoxidil',
//           image: '',
//           type: 'Other',
//           smell: 'Other',
//           color: 'Other',
//           casNo: '148553-50-8',
//           storage: 'Ankleshwar',
//           placeOfOrigin: 'Other',
//           hsCode: '2942',
//           price: '7500',
//         },
//         {
//           name: 'Pregabalin',
//           image: '',
//           casNo: '5593-20-4',
//           storage: 'Room Temperature',
//           placeOfOrigin: 'India',
//           grade: 'Medicine Grade',
//           form: 'Powder',
//           smell: 'Other',
//           purity: 'High',
//           price: '7500',
//         },
//         {
//           name: 'Fenofibrate',
//           image: '',
//           casNo: '18559-94-9',
//           placeOfOrigin: 'Ankleshwar',
//           molecularFormula: 'C13H21NO3',
//           price: '7500',
//         },
//         {
//             name: 'Rabeprazole',
//             image: '',
//             grade: 'Medicine Grade',
//             otherNames: '1-Naphthalene Methylamine Hydrochloride Ambroxol HCL81',
//             storage: 'Room Temperature',
//             placeOfOrigin: 'India',
//             purity: 'High',
//             supplyAbility: '5000 per Month',
//             price: '7500',
//         },
//         {
//           name: 'Pantoprazole',
//           image: '',
//           supplyAbility: '5000 per Month',
//           placeOfOrigin: 'India',
//           storage: 'Room Temperature',
//           purity: 'High',
//           application: 'Pharmaceutical Industry',
//         },
//         {
//           name: 'Dextromethorphan Hbr',
//           image: '',
//           supplyAbility: '5000 per Month',
//           placeOfOrigin: 'India',
//           storage: 'Room Temperature',
//           purity: 'High',
//           application: 'Pharmaceutical Industry',
//         },
//         {
//           name: 'Artemether',
//           image: '',
//           supplyAbility: '5000 per Month',
//           placeOfOrigin: 'India',
//           storage: 'Room Temperature',
//           purity: 'High',
//           application: 'Pharmaceutical Industry',
//         },
//       ],
//     },
//   ];
  
export const productData = [
  {
    "category": "Bulk Drugs API",
    "products": [
      {
        "name": "Minoxidil",
        "image": assets.minoxidil,
        "smell": "Alcohol smell",
        "color": "white to off-white, crystalline powder",
        "casNo": "38304-91-5",
        "storage": "",
        "placeOfOrigin": "",
        "hsCode": "30049099 ",
        "price": "",
        "description":"Minoxidil, Powder, USP is an antihypertensive vasodilator used to help hair growth in the treatment of male pattern baldness. All Spectrum Chemical USP products are manufactured, packaged and stored under current Good Manufacturing Practices (cGMP) per 21CFR part 211 in FDA registered and inspected facilities."
      },
      {
        "name": "Pregabalin",
        "image": assets.pregabalin,
        "casNo": "148553-50-8",
        "storage": "",
        "placeOfOrigin": "",
        "grade": "",
        "form": "Powder",
        "smell": "",
        "purity": "High",
        "price": "",
        "description": "Pregabalin API is a key active pharmaceutical ingredient used in medications to treat neuropathic pain, epilepsy, and generalized anxiety disorder. It works by reducing abnormal electrical activity in the brain and nerves. Known for its effectiveness in pain management and seizure control, pregabalin is available in various dosage forms, ensuring broad therapeutic use."
      },
      {
        "name": "Fenofibrate",
        "image": assets.fenofibrate,
        "casNo": "49562-28-9",
        "placeOfOrigin": "",
        "molecularFormula": "C20H21ClO4",
        "price": "",
        "description":"Fenofibrate increases high density lipoprotein levels by reducing cholesteryl ester transfer protein expression. It is used in treating the condition of increased cholesterol levels in the blood, abnormal lipid levels in the body and also hypertriglyceridaemia. Fenofibrate is a lipid regulating drug and proliferator-activated receptor-α (PPARα) mediates its action. It decreases the plasma levels of fibrinogen and C-reactive protein. By this fenofibrate allows better flow-mediated dilatation and reduces the risk of atherosclerosis. Fenofibrate is also known to reduce uric acid levels."
      },
      {
        "name": "Rabeprazole",
        "image": assets.rabeprazole,
        "grade": "Medicine Grade",
        "otherNames": "1-Naphthalene Methylamine Hydrochloride Ambroxol HCL81",
        "storage": "Room Temperature",
        "placeOfOrigin": "India",
        "purity": "High",
        "supplyAbility": "5000 per Month",
        "price": "7500",
        "description": "Rabeprazole is used to treat duodenal ulcers, gastroesophageal reflux disease (GERD), and Zollinger-Ellison syndrome, a condition where the stomach produces too much acid. It may also be used together with antibiotics (eg, amoxicillin, clarithromycin) to treat ulcers associated with infections caused by the H. pylori bacteria. Rabeprazole is a proton pump inhibitor (PPI) that decreases the amount of acid produced by the stomach."
      },
      {
        "name": "Pantoprazole",
        "image": assets.pantaprazole,
        "supplyAbility": "5000 per Month",
        "placeOfOrigin": "",
        "storage": "",
        "purity": "High",
        "application": "Pharmaceutical Industry",
        "description":"Pantoprazole is used to treat certain conditions in which there is too much acid in the stomach. It is used to treat erosive esophagitis or 'heartburn' caused by gastroesophageal reflux disease (GERD), a condition where the acid in the stomach washes back up into the esophagus."
      },
      

{
        "name": "Dextromethorphan Hbr",
        "image": assets.dextromethorphan,
        "supplyAbility": "5000 per Month",
        "placeOfOrigin": "",
        "storage": "",
        "purity": "High",
        "application": "Pharmaceutical Industry",
        "description": "Dextromethorphan is a 6-methoxy-11-methyl-1,3,4,9,10,10a-hexahydro-2H-10,4a-(epiminoethano)phenanthrene in which the sterocenters at positions 4a, 10 and 10a have S-configuration. It is a prodrug of dextrorphan and used as an antitussive drug for suppressing cough. It has a role as a NMDA receptor antagonist, a neurotoxin, a xenobiotic, an environmental contaminant, an antitussive, a prodrug and a oneirogen. It is functionally related to a dextrorphan. It is an enantiomer of a levomethorphan."
      },
      {
        "name": "Artemether",
        "image": assets.artemether,
        "supplyAbility": "5000 per Month",
        "placeOfOrigin": "",
        "storage": "",
        "purity": "High",
        "application": "Pharmaceutical Industry",
        "description": "Artemether is an artemisinin derivative that is artemisinin in which the lactone has been converted to the corresponding lactol methyl ether. It is used in combination with lumefantrine as an antimalarial for the treatment of multi-drug resistant strains of falciparum malaria. It has a role as an antimalarial. It is a sesquiterpenoid, a cyclic acetal, an organic peroxide, an artemisinin derivative and a semisynthetic derivative."
      }
    ]
  },
  {
    "category": "API Intermediates",
    "products": [
      {
        "name": "2-Amino Acetophenone",
        "casNo": "613-89-8",
        "image": assets.aminoAcetophenone2,
        "type": "",
        "smell": "",
        "color": "yellow to dark brown",
        "storage": "",
        "placeOfOrigin": "",
        "hsCode": "2922.3900",
        "price": "",
        "description": "2-Aminoacetophenone is a type of pharmaceutical grade intermediate which is accessible in yellow liquid form. Its molar mass is 135.16. This substance is used to formulate anti infective quinolones. Its melting point is 20degree C and boiling point ranges between 85 degree C to 90 degree C respectively. Flash point of 2-Aminoacetophenone is more than 230 degree F. Molar mass of this substance is 135.16. Standard of this chemical has been tested on the basis of its shelf life and composition."
      },
      {
        "name": "2-(Chloromethyl)-4-methylquinazoline",
        "image": assets.chloromethyl4methylquinazoline1,
        "casNo": "109113-72-6 ",
        "storage": "",
        "placeOfOrigin": "",
        "grade": "",
        "form": "Powder",
        "smell": "",
        "purity": "High",
        "price": "",
        "description":"2-(Chloromethyl)-4-methylquinazoline, is a building block used for the preparation of Linagliptin (L465900), and its impurities, acting as type 2 diabetes drugs."
      },
      {
        "name": "(R)-3-Amino-piperidine dihydrochloride",
        "image": assets.aminoPiperidineDihydrochloride,
        "casNo": "334618-23-4",
        "placeOfOrigin": "",
        "molecularFormula": "C5H14Cl2N2 ",
        "price": "",
        "description": "(R)-3-Aminopiperidine dihydrochloride, as a chiral compound, is an important intermediate in the synthesis of chemicals and pharmaceuticals. It has important applications in the chemical and pharmaceutical industries. It is a new fine chemical, low-toxic pesticide, and high additive It is an important intermediate for medicine and chemical additives. The pharmaceutical industry is mainly used to synthesize dipeptidyl peptidase IV (DPP-IV) inhibitors, such as troglitin, alogliptin and other diabetes drugs. The market demand is large. It has certain research significance."
      },
      {
        "name": "8-Bromo-7-(2-butynl)-3,7-dihydro-3-methyl-1H-purine-2,6-dione",
        "casNo": "666816-98-4",
        "image": assets.bromo72Butynl37Dihydro3Methyl1hPurine26dione,
        "grade": "",
        "otherNames": "3-methyl-7-(2-butyn-1-yl)-8bromoxanthine",
        "storage": "",
        "placeOfOrigin": "",
        "purity": "High",
        "supplyAbility": "",
        "description": "8-Bromo-7-(2-butynl)-3,7-dihydro-3-methyl-1H-purine-2,6-dione, with the chemical formula C12H10BrN4O2 is a compound known for its potential applications in various fields. This compound, also known as 8-Bromo-7-(but-2-ynyl)-3-methylxanthine, is characterized by its bromine, butynyl, and methyl functional groups. It is a purine derivative and belongs to the class of xanthine derivatives. Although specific applications of this compound are not widely documented, its unique structure suggests potential use in pharmaceutical research and drug development."
      }
    ]
  }
]