import React, { useState } from 'react';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { Drawer } from 'antd';
import LanguageDropdown from './LanguageDropdown';
import { Link } from 'react-router-dom';
import { assets } from "../utils/helper/media";

export const dropDownOptions = [
    { label: 'Option1', value: 'Option1' },
    { label: 'Option2', value: 'Option2' },
    { label: 'Option3', value: 'Option3' }
]
export default function Header() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    return (
        <div className=''>
            <div className=' relative z-[1] flex justify-center self-stretch bg-[#072C2C]'>
                <div className='container mt-1 flex justify-center  py-1'>
                    <div className='flex w-full items-center justify-around md:justify-between gap-5 '>
                        <div className='flex  items-center gap-4'>
                            {/* <Link to='/' className='hidden md:block'> */}
                            <Link to={'/'} className='hidden md:block'>
                                <img src={assets.img_call} alt='Call' className='h-[16px] w-[16px]' />
                            </Link>
                            {/* </Link> */}
                            <p size='headingxs' className='text-[8px] sm:text-[10px] md:text-[14px] font-bold text-white'>
                                08045477087
                            </p>
                        </div>
                        <div className='flex flex-wrap gap-1'>
                            <p size='textxs' className='text-[8px] sm:text-[10px] md:text-[14px] font-normal text-gray-100'>
                                Trusted company that deals in
                            </p>
                            <p size='headingxs' className='text-[8px] sm:text-[10px] md:text-[14px] font-bold text-teal-200'>
                                quality-made products
                            </p>
                        </div>
                        {/* <p className='text-white md:text-[14px] text-[10px]'>Language</p> */}
                        <LanguageDropdown />

                        {/* <SelectBox
                            shape='square'
                            indicator={
                                <img
                                    src='images/img_chevrondownxssvgrepocom.svg'
                                    alt='Chevron-down-xs Svgrepo.com'
                                    className='h-[16px] w-[16px]'
                                />
                            }
                            name='chevrondownxssv'
                            placeholder={`Languages`}
                            options={dropDownOptions}
                            className='w-[8%] gap-4 font-bold text-white-a700 md:w-full'
                        /> */}
                    </div>
                </div>
            </div>
            <div className=' flex justify-center self-stretch bg-white-700 py-1.5' >
                <div className='container flex items-center w-full justify-between md:justify-between   '>
                    <MdMenu className='md:hidden size-8 w-[10%]' onClick={() => setOpen(true)} />

                    <div className='flex  flex-col items-start mx-auto md:justify-center text-center w-[50%] md:w-[18%] cursor-pointer' onClick={() => navigate('/')}>
                        {/* <div className='w-[80%] mx-auto   '> */}
                            <div className='flex items-center justify-center'>

                                <img src={assets.final_logo} alt='Rutain' className=' w-[100%] object-contain' />
                                {/* <div className='  flex flex-1 flex-col '>
                                    <span
                                        size='textxl'

                                        className='font-jacquesfrancois text-[16px] md:text-[20px] font-normal uppercase tracking-[6.00px] text-teal-400'
                                    >
                                        RutAin
                                    </span>
                                    <span
                                        size='textxs'

                                        className='font-jacquesfrancois text-[8px] font-normal uppercase tracking-[3px] md:tracking-[4.80px] text-teal-300'
                                    >
                                        PharmaCHEM
                                    </span>
                                </div> */}
                            {/* </div> */}
                        </div>
                        {/* <span
                            size='texts'

                            className='self-center font-judson text-[8px]  font-normal uppercase tracking-[2.5px] text-gray-500'
                        >
                            Birth for Quality
                        </span> */}
                    </div>
                    <div className='md:w-[70%] w-[0%] gap-[18px] sm:flex-col items-center hidden md:flex'>
                        <ul className='flex flex-wrap gap-[18px] items-center'>
                            <li>
                                {/* <Link to={'/home'}>Home</Link> */}
                                <Link to='/'>
                                    <span className='font-poppins text-[12px] lg:text-[16px] font-medium text-gray-900'>
                                        Home
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/company-profile'>
                                    <span className='font-poppins text-[12px] lg:text-[16px] font-medium text-gray-900'>
                                        Company Profile
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/product'>
                                    <span className='font-poppins text-[12px] lg:text-[16px] font-medium text-gray-900'>
                                        Our Products
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/blogs'>
                                    <span className='font-poppins text-[12px] lg:text-[16px] font-medium text-gray-900'>
                                        Our Blogs
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/contact-us'>
                                    <span className='font-poppins text-[12px] lg:text-[16px] font-medium text-gray-900'>
                                        Contact Us
                                    </span>
                                </Link>
                            </li>

                            {/* <Link to='/'>
                                <img src='/images/img_rewind.svg' alt='Rewind' className='h-[24px] w-[24px] sm:w-full' />
                            </Link> */}
                        </ul>
                    </div >
                    <div className='w-[0%] md:w-[15%]'>
                        <Button
                            shape='round'
                            className=' hidden md:block min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out'
                            // style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                            onClick={() => navigate('/contact-us')}
                        >
                            Get a Quote
                        </Button>
                    </div>


                </div>
            </div >
            <Drawer open={open} closable={true} onClose={() => setOpen(false)} width={'80%'} title={'Rutain Pharma'} placement='left'>
                <div className=' '>
                    <ul className=''>
                        <li className='my-2 border-b-2 pb-2'>
                            {/* <Link to={'/home'}>Home</Link> */}
                            <Link to='/'>
                                <span className='font-poppins text-[16px] font-medium text-gray-900'>
                                    Home
                                </span>
                            </Link>
                        </li>
                        <li className='my-2 border-b-2 pb-2'>
                            <Link to='/company-profile'>
                                <span className='font-poppins text-[16px] font-medium text-gray-900'>
                                    Company Profile
                                </span>
                            </Link>
                        </li>
                        <li className='my-2 border-b-2 pb-2'>
                            <Link to='/product'>
                                <span className='font-poppins text-[16px] font-medium text-gray-900'>
                                    Our Products
                                </span>
                            </Link>
                        </li>
                        <li className='my-2 border-b-2 pb-2'>
                            <Link to='/blogs'>
                                <span className='font-poppins text-[16px] font-medium text-gray-900'>
                                    Our Blogs
                                </span>
                            </Link>
                        </li>
                        <li className='my-2 border-b-2 pb-2'>
                            <Link to='/contact-us'>
                                <span className='font-poppins text-[16px] font-medium text-gray-900'>
                                    Contact Us
                                </span>
                            </Link>
                        </li>

                    </ul>
                </div >
                <Button
                    shape='round'
                    className=' my-5 min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out'
                    // style={{ boxShadow: '0 4px 110px rgba(105, 223, 208, 1)' }}
                >
                    Get a Quote
                </Button>
            </Drawer>
        </div >
    );
}