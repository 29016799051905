import { useLocation, useNavigate } from "react-router-dom"
import { assets } from "../utils/helper/media";

const Footer = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    return (
        <div className="bg-[#072C2C] ">
            <div className="relative">
                <div className={`${pathname !== '/contact-us' && 'mt-20'}  container`}>
                    <img src={assets.footer_bg} alt="rutain pharma" className="absolute right-0 bottom-0 w-[30%] md:w-[15%] mt-5" />
                    <div className="flex flex-col lg:flex-row items-center gap-5 2xl:gap-20 ">
                        <div className="relative lg:relative w-fit text-inter lg:absolute lg:-top-14">
                            <img src={assets.vector_5} alt="rutain pharma" className="w-full h-full" />
                            <div className="w-[60%]  text-[#175856] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[55%]">
                                <p className="text-center font-inter ml-1 text-[24px] 2xl:text-[28px] mb-[14px] 2xl:mb-[20px]  font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]">Contact US</p>
                                <div className="flex gap-3 items-start">
                                    <img src={assets.building} alt="rutain pharma" className="w-4 pt-1" />
                                    <p className="text-[14px] 2xl:leading-[24px] leading-[18px]">B No. Plot No. 4307 - 4308, GIDC, Ankleshwar - 393002, Gujarat, India</p>
                                </div>
                                <div className="text-[14px] flex gap-3 2xl:mt-[12px] mt-[8px] items-start">
                                    <img src={assets.person} alt="rutain pharma" className="w-4 pt-1" />
                                    <div>
                                        <p>Mr Chintan (CEO)</p>
                                        <p className="mt-[8px]">Mobile :08045477087</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-10 text-white justify-center lg:justify-around w-[100%] lg:w-[60%]">
                            <div className="text-[14px] text-center  font-inter lg:text-left">
                                <p className="text-[24px] mb-5 font-inter font-bold 2xl:text-[32px]">Quick Links</p>
                                <p className="cursor-pointer mt-2" onClick={() => navigate('/')}>Home Page</p>
                                <p className="cursor-pointer mt-2" onClick={() => navigate('/company-profile')}>Company Profile</p>
                                <p className="cursor-pointer mt-2" onClick={() => navigate('/product')}>Our Products</p>
                                <p className="cursor-pointer mt-2" onClick={() => navigate('/contact-us')}>Contact US</p>
                            </div>
                            <div className="text-[14px] text-center font-inter lg:text-left">
                                <p className="text-[24px] mb-5  font-bold 2xl:text-[32px]">Our Category</p>
                                <p className="cursor-pointer mt-2">API Intemediates</p>
                                <p className="cursor-pointer mt-2">Bulk Drug API</p>
                                <p className="cursor-pointer mt-2">Active Pharmaceutical Ingredients</p>
                                <p className="cursor-pointer mt-2">Industrial chemicals</p>
                                <p className="cursor-pointer mt-2">Antibiotic Medicines</p>
                                <p className="cursor-pointer mt-2">Cardiac Medicines</p>
                            </div>
                            <div className="text-[14px] text-center font-inter lg:text-left">
                                <img src={assets.trusted_seller} alt="rutain pharma" className="mb-5 mx-auto" />
                                <p className="cursor-pointer mt-2 flex justify-center lg:justify-start  gap-2"><img src={assets.send} alt="rutain pharma" className="" />Send Inquiry</p>
                                <p className="cursor-pointer mt-2 flex justify-center lg:justify-start  gap-2"><img src={assets.sms} alt="rutain pharma" className="" />Send Email</p>
                                <p className="cursor-pointer mt-2 flex justify-center lg:justify-start  gap-2"><img src={assets.call_1} alt="rutain pharma" className="" />Call Us</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" text-white text-[10px] bg-[#072C2C] border-t border-[#18898396] lg:text-[14px] flex justify-center gap-2 py-1.5 mt-1 ">
                <p className="uppercase ">RUTAIN PHARMA CHEM All Rights Reserved | </p>
                <p className="text-[#A1EEE1]">Terms of Use</p>
            </div>
        </div>
    )
}
export default Footer