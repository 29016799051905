// ProductCard.js
import React from 'react';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ product, index,category }) => {
  const navigate = useNavigate()
  const handleSendInquiry = (product) => {

    navigate(`/product-details/${category.replaceAll(' ','-')}/${product?.name?.replaceAll(' ','-')}`, { state: { product } });
  }; 
  return (
    <div className={`p-2 mt-3 md:mt-0 md:p-6 bg-white ${index!==6&&'border-b-[2px]'} ${index%2===0&&'md:border-r-[2px]'} border-[#DFDFDF]`}>
      <h3 className="mb-2 text-[22px] border-[#AFAFAF] border-b-[2px] font-semibold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
        {product.name}
      </h3>
      <div className="flex gap-5">
        <div className="my-auto">
          <img src={product.image} alt="Product" />
        </div>
        <div className="w-[60%]">
                        {product.casNo && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>CAS No: <span className='text-gray-600 text-right'>{product.casNo}</span></p>}
                        {product.storage && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Storage: <span className='text-gray-600 text-right'>{product.storage}</span></p>}
                        {product.color && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Color: <span className='text-gray-600 text-right'>{product.color}</span></p>}
                        {product.form && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Form: <span className='text-gray-600 text-right'>{product.form}</span></p>}
                        {product.smell && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Smell: <span className='text-gray-600 text-right'>{product.smell}</span></p>}
                        {product.placeOfOrigin && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Place of Origin: <span className='text-gray-600 text-right'>{product.placeOfOrigin}</span></p>}
                        {product.grade && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Grade: <span className='text-gray-600 text-right'>{product.grade}</span></p>}
                        {product.purity && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Purity: <span className='text-gray-600 text-right'>{product.purity}</span></p>}
                        {product.supplyAbility && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Supply Ability: <span className='text-gray-600 text-right'>{product.supplyAbility}</span></p>}
                        {product.application && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Application: <span className='text-gray-600 text-right'>{product.application}</span></p>}
                        {product.otherNames && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Other Names: <span className='text-gray-600 text-right'>{product.otherNames}</span></p>}
                        {product.molecularFormula && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Molecular Formula: <span className='text-gray-600 text-right'>{product.molecularFormula}</span></p>}
                    </div>
      </div>
      {/* {product.price && (
        <p className="mb-2 text-[22px] text-center mt-5 font-semibold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
          Price: {product.price} INR/Kilograms
        </p>
      )} */}
      <Button
        shape="round"
        className={`min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black ${index % 2 === 0 ? 'bg-[#A1EEE1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)]' : 'bg-[#FECCE3] hover:shadow-[0_4px_163px_rgba(233,31,100,1)]'
          }  transition-all duration-300 ease-in-out`}
      // style={{
      //   boxShadow:
      //     index % 2 === 0
      //       ? '0 6px 30px rgba(105, 223, 208, 0.1)'
      //       : '0 6px 30px rgba(233, 31, 100, 0.1)',
      // }}
      onClick={() => handleSendInquiry(product)}
      >
        More Info
      </Button>
    </div>
  );
};

export default ProductCard;
